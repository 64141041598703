<template>
	<el-dialog
		top="1vh"
		width="30%"
		:visible.sync="is_show_in_page"
	>
	    <img src="../res/img/logo.png" style="width:60px;height: 60px;">
		<div style="text-align: center;">
			<div class="under_text">{{bl_name}}</div>
			<div class="under_text">{{bl_num}}</div>
			<div class="under_text" style="font-size: 12px;margin-top: 8px;color: #8F939C;">微信扫一扫，接货没烦恼</div>
			<img :src="src" style="width:75%;">
			<div class="bl_item"  style="display: flex;text-align: center;align-items: center;justify-content: center;">
				<div>
					<div class="start">发</div>
				</div>
			<div>
				<div style="font-size: 20px;">{{bl_data.case_prov}}</div>
				<div style="font-size: 12px;">{{case_addr_text}}</div>
			</div>
			</div>
			<div style="margin-left: 53%;width: 10px;">↓</div>
				<div class="bl_item"  style="display: flex;text-align: center;align-items: center;justify-content: center;">
					<div class="end">到</div>
					<div>
					<div style="font-size: 20px;">{{bl_data.aim_prov}}</div>
					<div style="font-size: 12px">{{aim_arr_text}}</div>
				</div>
			</div>
		</div>
		
		<!-- <div class="under_text">{{bl_data.case_city}}</div>
		<div class="under_text">{{case_addr_text}}</div>
		<div class="under_text">↓</div>
		<div class="under_text">{{bl_data.aim_city}}</div>
		<div class="under_text">{{aim_arr_text}}</div> -->
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			src:String,
			bl_data:Object
		},
		data() {
			return {
				case_addr_text:'',
				aim_arr_text:'',
				bl_name:'',
				bl_num:'',
				//是否显示
				is_show_in_page:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.init(this.bl_data)
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			init(item){
				this.case_addr_text=`${item.case_city}${item.case_county}${item.case_other_obj.addr_info}`;
				this.aim_arr_text=`${item.aim_city}${item.aim_county}${item.aim_other_obj.addr_info}`;
				if(item.bl_name){
					this.bl_name = item.bl_name
				}else{
					this.bl_name = item.cargo_name
				}
				if(item.bl_num){
				   this.bl_num = item.bl_num
				}else{
				   this.bl_num =item.truck_tord_num
				}
			}
		}
	}
</script>
<style scoped>
.under_text{
	text-align: center;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
}
		.bl_item{
			width: 100%;
		}
		.start{
			background-color: #55aaff;
			color: #ffffff;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			line-height: 30px;
			text-align: center;
			margin-right: 10px;
		}
		.end{
			background-color: #ff0000;
			color: #ffffff;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			line-height: 30px;
			text-align: center;
			margin-right: 10px;
		}
</style>