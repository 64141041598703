<template>
	<div>

		<!-- 凭证界面 -->
		<el-dialog title="凭证列表" top="1vh" width="90%" :visible.sync="is_show_in_Page">
			<div class="voucher">
				<div class="header">
					<el-button type="primary" @click="voucher_add">上传</el-button>
				</div>
				<div class="list" v-if="list.length>0">
					<div class="item" v-for="(voucher_item,index) in list" :key="index">
						<div class="del_btn" @click="voucher_del(index)">×</div>
							<el-image
								class="img"
								:src="voucher_item.src"
								:previewSrcList="voucher_item.srcList"
								:z-index = 3000
								>
							</el-image>
						<div class="text">{{voucher_item.mark}}({{voucher_item.type_text}})<div v-if="voucher_item.file_num">磅单编号：{{voucher_item.file_num}}</div></div>
					</div>
				</div>
				<div class="list_none" v-else>
					无数据
				</div>
			</div>
		</el-dialog>


		<!-- 凭证添加界面 -->
		<el-dialog title="添加凭证" top="2vh" width="500px" :visible.sync="upl.is_show">
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
					<img v-if="upl.src" :src="upl.src" class="show">
					<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>
				<textarea placeholder="凭证备注" class="mark_area" v-model="upl.mark"></textarea>
				<div class="btn_area">
					<el-button type="primary" @click="voucher_sub">上传</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		props:{
			truck_tord_num:String,//运单编号
			is_show:Number,//是否显示
			weight:'',
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//运单编号
				truck_tord_num_in_Page:'',

				//凭证列表
				list:[],

				//上传界面
				upl:{

					//是否显示上传界面
					is_show:false,

					//地址
					src:'',

					//备注
					mark:''
				}
			}
		},
		mounted() {
			
		},
		watch:{
			truck_tord_num(new_data){
				this.truck_tord_num_in_Page=new_data
			},
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		methods: {

			//初始化
			init(){

				//如果没有订单编号则不读取数据
				if(!this.truck_tord_num_in_Page){
					return;
				}

				//读取数据
				this.load_data()
			},


			//删除凭证
			voucher_del(index){

				//询问
				this.$my.other.confirm({
					content:'确定删除凭证',
					confirm:()=>{
						
						//执行删除
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_voucher_del',
								voucher_id:this.list[index].voucher_id,
							},
							callback:(data)=>{

								//刷新数据
								this.load_data()
							}
						});
					}
				});
			},


			//凭证相关操作
			voucher_sub(){

				//上传
				this.$my.qiniu.upl({
					bucket: 'trucktordrealtimevoucher',
					file_path: this.upl.src,
					callback: (data) => {
						var key = data.key.split('__v__1')
						//上传
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_voucher_add_admin',
								truck_tord_num:this.truck_tord_num_in_Page,
								img_key:key[0],
								mark:this.upl.mark
							},
							callback:(data)=>{
								
								//关闭添加凭证界面
								this.upl.is_show=false;

								//刷新数据
								this.load_data()
							}
						});
					}
				});
			},
			voucher_add(){//打开添加凭证界面

				//打开上传界面
				this.upl.is_show=true;

				//清空上次上传的图片
				this.upl.src=''

				//清空备注
				this.upl.mark=''
			},
			voucher_add_sub(){//提交凭证

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {

						//预览
						this.upl.src=res.src
					}
				});
			},


			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_voucher_list',
						truck_tord_num:this.truck_tord_num_in_Page
					},
					callback:(data)=>{

						//构造数据
						let list=[];
						for(var item of data.list){

							//上传人
							switch(item.upl_user_type){
								case '1':var upl_type_text="货主上传";break;
								case '2':var upl_type_text="司机上传";break;
								case '3':var upl_type_text="后台上传";break;
								case '4':var upl_type_text="货主子账号上传";break;
								case '5':var upl_type_text="网点上传";break;
							}
							switch(item.type){
								case '1':var type_text="磅单";break;
								case '2':var type_text="回单";break;
								case '3':var type_text="现场照片";break;
							}
							var srcList = []
							srcList.push(this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key))
							list.push({
								type_text:type_text,
								file_num:item.file_num,
								voucher_id:item.id,
								src:this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key),
								srcList:srcList,
								mark:item.mark?upl_type_text+"(备注:"+item.mark+")":upl_type_text
							})
						}
						this.list=list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.upl{
		.img_area{
			border:1px solid #ccc;
			cursor: pointer;
			padding: 5px;
			.show{
				margin: auto;
				display: block;
				max-width: 100%;
				max-height: 100%;
			}
			.icon{
				text-align: center;
				display: block;
				font-size: 100px;
				height: 500px;
				line-height: 500px;
			}
		}
		.mark_area{
			border: 1px solid #ccc;
			padding: 5px;
			width: 448px;
			margin-top: 10px;
			height: 100px;
			outline: none;
			font-size: 16px;
		}
		.mark_area::placeholder {
			font-size: 20px;
			line-height: 100px;
			text-align: center; 
		}
		.btn_area{
			text-align: center;
			margin-top: 15px;
		}
	}

	.voucher{
		margin-top: -10px;
		.header{
			padding-bottom: 20px;
			border-bottom: 1px solid #ccc;
		}
		.list_none{
			height:300px;
			line-height:300px;
			text-align:center;
			font-size: 18px;
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 30px;
			.item{
				margin: 15px 0.4% 0 0.4%;
				width: 19%;
				height: 19vw;
				position: relative;
				border: 1px solid #ccc;
				border-radius: 5px;
				.del_btn{
					z-index: 4000;
					font-size: 20px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 20px;
					color: #fff;
					background-color: #000;
					opacity: 0.5;
					position: absolute;
					right: -10px;
					top: -10px;
					cursor: pointer;
				}
				.img{
					max-width: 95%;
					max-height: calc( 100% - 20px - 1vw);
					display: block;
					margin: 0.3vw auto 0 auto;
				}
				.text{
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}
		}
	}
</style>